import Description from 'components/Description';
import React, { useEffect, useState } from 'react';
import s1 from 'assets/soojyeong/1.jpg';
import s2 from 'assets/soojyeong/2.jpg';
import s3 from 'assets/soojyeong/3.jpg';
import s4 from 'assets/soojyeong/4.jpg';
import s5 from 'assets/soojyeong/5.jpg';
import s6 from 'assets/soojyeong/6.jpg';
import s7 from 'assets/soojyeong/7.jpg';
import s8 from 'assets/soojyeong/8.jpg';
import SooJyeongPresenter from './SooJyeongPresenter';

function SooJyeongContainer(props) {
  const [enter, setEnter] = useState(true);
  const [array, setArray] = useState([
    s1,
    s2,
    s3,
    s4,
    s5,
    s6,
    s7,
    s8,
    s1,
    s2,
    s3,
    s4,
    s5,
    s6,
    s7,
    s8,
  ]);

  const [opened, setOpened] = useState([]);
  const [completedOpen, setcompletedOpen] = useState([]);
  const cardOpen = (item, index) => {
    const sooCards = [...document.getElementsByClassName('soo-card')];
    if (sooCards[index].style.transform) {
      sooCards[index].style.transform = `rotateY(${
        (sooCards[index].style.transform.includes('360') && '0') || '360'
      }deg)`;
    } else {
      sooCards[index].style.transform = 'rotateY(0deg)';
      sooCards[index].style.transform = 'rotateY(360deg)';
    }

    if (opened.length === 0) {
      setOpened([index]);
    } else if (opened.length === 1) {
      // 두개 오픈
      setOpened([...opened, index]);

      setTimeout(() => {
        if (array[opened[0]] === item) {
          setOpened([]);
          setcompletedOpen([...completedOpen, opened[0], index]);
        } else {
          setOpened([]);
        }
      }, [700]);
    }
  };

  const shuffle = () => {
    setArray(array.sort(() => Math.random() - 0.5));
  };

  useEffect(() => {
    shuffle();
  }, []);

  return (
    <>
      {enter && (
        <Description
          close={() => setEnter(false)}
          title="수정수경수졍"
          content="수정이와 수경이를 헷갈리지 말고 찾아내보자!"
        />
      )}
      <SooJyeongPresenter
        array={array}
        cardOpen={cardOpen}
        opened={opened}
        completedOpen={completedOpen}
      />
    </>
  );
}

export default SooJyeongContainer;
