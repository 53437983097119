import { Div, ImageComponent, Row } from 'differoute-styles';
import React from 'react';
import styled, { keyframes } from 'styled-components';
import jaejoon from 'assets/jaejoon/jaejoon.png';
import santa from 'assets/jaejoon/santa.png';
import woongeun from 'assets/jaejoon/woongeun.png';
import balloon from 'assets/jaejoon/balloon-end.png';

const Ballon = styled(Div)`
  background: rgb(255, 168, 153);
  background: linear-gradient(
    287deg,
    rgba(255, 168, 153, 1) 0%,
    rgba(255, 207, 185, 1) 11%,
    rgba(255, 168, 153, 1) 93%
  );
  transform: translateX(-50%);
  transition: width 0.1s linear;

  &:after {
    position: absolute;
    content: ' ';
    background: rgb(255, 255, 252);
    background: linear-gradient(
      287deg,
      rgba(255, 255, 252, 1) 0%,
      rgba(255, 222, 209, 1) 53%,
      rgba(255, 205, 196, 1) 93%
    );
    right: 15%;
    top: 15%;
    width: 10%;
    height: 30%;
    border-radius: 20px;
    transform: skew(30deg);
  }
`;

const bounce = keyframes` 0% {
  -webkit-transform: translateY(-45px);
          transform: translateY(-45px);
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
  opacity: 1;
}
6% {
  opacity: 1;
}
10% {
  -webkit-transform: translateY(-24px);
          transform: translateY(-24px);
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
}
16% {
  -webkit-transform: translateY(-12px);
          transform: translateY(-12px);
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
}
20% {
  -webkit-transform: translateY(-6px);
          transform: translateY(-6px);
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
}
23% {
  -webkit-transform: translateY(-4px);
          transform: translateY(-4px);
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
}
6%,
13%,
18%,
22% {
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
}
25% {
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  opacity: 1;
}`;

const BouceWoong = styled(ImageComponent)`
  animation: ${bounce} 3.6s infinite both;
`;

const shaking = keyframes`
0%{
transform: rotate(5deg);
}50%{
  transform: rotate(-5deg);
}100%{
  transform: rotate(5deg);
} 
`;

const ShakingFace = styled(ImageComponent)`
  animation: ${shaking} 3s linear infinite both;
`;

function JaejoonPresenter({ play, isRunning, persentage }) {
  return (
    <Row alignItems="center" justifyContent="center" width="100%" height="100%">
      <Div position="absolute" width={180} height={382} left={200} top="250px">
        <ShakingFace
          src={jaejoon}
          width="100%"
          position="absolute"
          top="0"
          zIndex={1}
        />
        <ImageComponent
          src={santa}
          width="50%"
          left="50%"
          transform="translateX(-50%)"
          position="absolute"
          top="265"
          zIndex={0}
        />
      </Div>
      <BouceWoong
        src={woongeun}
        width={270}
        position="absolute"
        top="200"
        right={120}
        zIndex={0}
      />
      <Div
        borderRadius="50%"
        width="70vh"
        aspectRatio="1 / 1"
        border={{
          color:
            (persentage > 80 && 'red') ||
            (persentage > 60 && '#E37C49') ||
            'primary',
          width: '20px',
        }}
        position="relative"
        onClick={play}
      >
        <Ballon
          id="balloon"
          position="absolute"
          bottom="0"
          left="50%"
          width="10vh"
          maxWidth="calc(70vh - 40px)"
          aspectRatio="1 / 1"
          borderRadius="50%"
        />
        <ImageComponent
          src={balloon}
          width={30}
          position="absolute"
          left="50%"
          transform="translateX(-50%)"
          bottom="-42px"
        />
      </Div>
    </Row>
  );
}

export default JaejoonPresenter;
