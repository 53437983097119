import Description from 'components/Description';
import { quizs } from 'data/quiz';
import { Div } from 'differoute-styles';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import QuizPresenter from './QuizPresenter';

function QuizContainer(props) {
  const navigate = useNavigate();
  const { number } = useParams();
  const [quiz, setQuiz] = useState({});

  useEffect(() => {
    if (number && number !== '-1') {
      setQuiz(quizs[Number(number)]);
    }
  }, [number]);

  const [result, setResult] = useState();
  const [selectedAnswer, setSelectedAnswer] = useState([]);
  const selectAnswer = (index) => {
    if (selectedAnswer.includes(index)) {
      setSelectedAnswer([...selectedAnswer.filter((el) => el !== index)]);
    } else {
      setSelectedAnswer([...selectedAnswer, index]);
    }
    if (Array.isArray(quiz.answer)) {
      if (selectedAnswer.length > 0) {
        setTimeout(() => {
          console.log(selectedAnswer);
          setResult(
            ((selectedAnswer.includes(0) || selectedAnswer.includes(3)) &&
              (index === 0 || index === 3) &&
              '정답') ||
              '오답',
          );
        }, [500]);
      }
    } else {
      setTimeout(() => {
        setResult((quiz.answer === index && '정답') || '오답');
      }, [500]);
    }
  };

  const nextCheck = () => {
    setResult();
    setSelectedAnswer([]);
    if (result === '정답') {
      navigate(`/quiz/${Number(number) + 1}`);
    }
  };

  return (
    <>
      {number === '-1' && (
        <Description
          title="디퍼루트 알아보기"
          close={() => navigate('/quiz/0')}
        />
      )}
      <Div
        position="fixed"
        top="0"
        left="0"
        width="100vw"
        height="100vh"
        backgroundColor="rgba(0,0,0,0.7)"
        zIndex="-1"
      />
      <QuizPresenter
        quiz={quiz}
        selectAnswer={selectAnswer}
        result={result}
        nextCheck={nextCheck}
        selectedAnswer={selectedAnswer}
      />
    </>
  );
}

export default QuizContainer;
