import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { theme, ScrollToTop, NotFound } from 'differoute-styles';
import GlobalStyle from 'styles/GlobalStyle';
import Main from 'routes/Main';
import Menus from 'routes/Menus';
import Jaejoon from 'routes/Jaejoon';
import Donghak from 'routes/Donghak';
import SooJyeong from 'routes/SooJyeong';
import Quiz from 'routes/Quiz';
import Balance from 'routes/Balance';

function App(props) {
  const userTheme = {
    ...theme,
    colors: {
      ...theme.colors,
      primary: '#99E9DE',
    },
    buttonType: 'capsule',
  };

  return (
    <ThemeProvider theme={userTheme}>
      <GlobalStyle />
      <BrowserRouter>
        <ScrollToTop>
          <Routes>
            <Route path="*" element={<NotFound />} />
            <Route path="/" element={<Main />}>
              <Route path="" element={<Menus />} />
              <Route path="jaejoon" element={<Jaejoon />} />
              <Route path="donghak" element={<Donghak />} />
              <Route path="soojyeong" element={<SooJyeong />} />
              <Route path="quiz/:number" element={<Quiz />}></Route>
              <Route path="balance" element={<Balance />}></Route>
            </Route>
          </Routes>
        </ScrollToTop>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
