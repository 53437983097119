/* eslint-disable no-loop-func */
import React, { useCallback, useEffect, useState } from 'react';
import closebox from 'assets/donghak/box.png';
import openbox from 'assets/donghak/box_open.png';
import Description from 'components/Description';
import DonghakPresenter from './DonghakPresenter';

function DonghakContainer(props) {
  const gameArray = [
    { asset: 'binch1', score: 1 },
    { asset: 'binch2', score: 2 },
    { asset: 'donghak', score: 10 },
    { asset: 'binch3', score: 4 },
    { asset: 'binch4', score: 3 },
    { asset: 'binch5', score: 5 },
  ];

  const [isStart, setIsStart] = useState(false);
  const shakingBox = () => {
    setIsStart(true);
    let alreadyPosition = [];
    const maxWidth = window.innerWidth - window.innerWidth * 0.15;
    const maxHeight = window.innerHeight - window.innerWidth * 0.25;
    const boxs = [...document.getElementsByClassName('total-box')];

    const boxWidth = boxs[0].clientWidth;
    const boxHeight = boxs[0].clientHeight;

    boxs.forEach((box, boxIndex) => {
      let leftRandom = Math.round(Math.random() * maxWidth);
      let bottomRandom = Math.round(Math.random() * maxHeight);

      while (
        alreadyPosition.find(
          (position) =>
            (position.left + boxWidth > leftRandom &&
              position.left < leftRandom) ||
            (position.bottom + boxHeight > bottomRandom &&
              position.bottom < bottomRandom),
        )
      ) {
        leftRandom = Math.round(Math.random() * maxWidth);
        bottomRandom = Math.round(Math.random() * maxHeight);
        console.log(`while: ${leftRandom}`);

        if (alreadyPosition.length === 6) {
          break;
        }
      }

      alreadyPosition = [
        ...alreadyPosition,
        { left: leftRandom, bottom: bottomRandom },
      ];

      box.style.bottom = `${bottomRandom}px`;
      box.style.left = `${leftRandom}px`;

      console.log(alreadyPosition);
    });
  };

  const close = () => {
    const imgs = [...document.getElementsByClassName('random-box-img')];
    const boxs = [...document.getElementsByClassName('random-box')];

    imgs.forEach((img) => {
      img.style.bottom = '10%';
      img.style.opacity = 0;
    });

    setTimeout(() => {
      boxs.forEach((box) => {
        box.src = closebox;
      });
    }, [300]);
  };

  const [alreadyClick, setAlreadyClick] = useState([]);

  const open = (e, index) => {
    e.preventDefault();
    e.stopPropagation();
    if (isStart) {
      setAlreadyClick([...alreadyClick, index]);
      const totalBoxs = [...document.getElementsByClassName('total-box')];
      const imgs = [...document.getElementsByClassName('random-box-img')];
      const boxs = [...document.getElementsByClassName('random-box')];

      if (alreadyClick.includes(index)) {
        totalBoxs[index].style.display = 'none';
      } else {
        const p = document.createElement('p');
        p.className = 'score';
        p.style.bottom = '0%';
        p.style.opacity = '0';

        totalBoxs[index].appendChild(p);

        imgs[index].style.left = '10%';
        boxs[index].style.marginLeft = '-100px';
        boxs[index].style.width = '150%';
        boxs[index].src = openbox;
        boxs[index].style.transition = 'unset';

        setTimeout(() => {
          imgs[index].style.bottom = '20%';
          imgs[index].style.zIndex = 10;
          imgs[index].style.opacity = 1;

          p.style.fontSize = '40px';
          p.style.position = 'absolute';
          p.style.bottom = '100%';
          p.style.opacity = '1';
          p.style.zIndex = '100';
          p.style.left = '80%';
          p.innerText = `${gameArray[index].score}개`;
        }, [700]);
      }
    }
  };

  const handlePlay = useCallback((e) => {
    if (e.which === 32) {
      e.preventDefault();
      shakingBox();
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handlePlay);

    return () => {
      window.removeEventListener('keydown', handlePlay);
    };
  }, [handlePlay]);

  const [enter, setEnter] = useState(true);

  return (
    <>
      {enter && (
        <Description
          close={() => {
            setEnter(false);
            setTimeout(() => {
              close();
            }, [700]);
          }}
          title="동하기를 찾아라"
          content={`디퍼루트 친구들을 위해 선물 상자를 준비한 동하기!\n하지만 세상에 공짜는 없다!\n전빈츠 사이로 꽁꽁 숨은 동하기를 찾아 선물을 GET 해보자~!⭐️\n(스페이스바를 누를때마다 상자가 섞여요!)`}
        />
      )}
      <DonghakPresenter gameArray={gameArray} open={open} />
    </>
  );
}

export default DonghakContainer;
