import { Col, Div, Grid, ImageComponent, Row } from 'differoute-styles';
import React from 'react';
import logo from 'assets/soojyeong/logo.png';
import snow from 'assets/soojyeong/snow.png';

function SooJyeongPresenter({ array, cardOpen, opened, completedOpen }) {
  const snowIndex = [1, 3, 5, 7, 8, 10, 13, 16];
  return (
    <>
      <Div
        position="fixed"
        top="0"
        left="0"
        width="100vw"
        height="100vh"
        backgroundColor="rgba(255,255,255,0.4)"
      />
      <Row
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="100%"
      >
        <Grid gap={15} cols={4} alignItems="center" justifyContent="center">
          {array.map((item, index) => (
            <Row
              key={`${item}${index}`}
              backgroundColor="#191919"
              maxWidth={130}
              minWidth={130}
              aspectRatio="1 / 1"
              borderRadius="10px"
              alignItems="center"
              justifyContent="center"
              position="relative"
              transition="all 0.5s cubic-bezier(0.455, 0.030, 0.515, 0.955)"
              onClick={() => cardOpen(item, index)}
              overflow="hidden"
              className="soo-card"
              cursor="pointer"
            >
              {snowIndex.includes(index) && (
                <ImageComponent
                  src={snow}
                  width="100%"
                  position="absolute"
                  top="-2px"
                  zIndex={10}
                />
              )}
              {(opened.includes(index) || completedOpen.includes(index)) && (
                <ImageComponent width="100%" src={item} borderRadius="10px" />
              )}
              {!opened.includes(index) && (
                <ImageComponent
                  width={70}
                  src={logo}
                  position="absolute"
                  left="50%"
                  top="50%"
                  transform="translate(-50%,-50%)"
                  zIndex={10}
                />
              )}
            </Row>
          ))}
        </Grid>
      </Row>
    </>
  );
}

export default SooJyeongPresenter;
