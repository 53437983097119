import { Div, ImageComponent } from 'differoute-styles';
import React, { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import whiteLine from 'assets/lights/line.svg';
import yellowLine from 'assets/lights/yellow-line.svg';
import circles from 'assets/lights/circles.svg';
import circles1 from 'assets/lights/circles1.svg';
import circles2 from 'assets/lights/circles2.svg';

const lightingCircle = keyframes`0% {
  filter: blur(15px);
}
50% {
  filter: blur(0px);
}
100% {
  filter: blur(15px);
}`;

const Light = styled(Div)`
  width: 100vw;
  position: relative;
  aspect-ratio: 1280 / 210;

  img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .blur {
    filter: blur(15px);
  }

  .lights {
    animation: ${lightingCircle} 1.2s infinite both;
  }

  .blink {
    animation-delay: 0.6s;
  }
`;

function Lights({ ...props }) {
  return (
    <Light id="lightsDocument" zIndex={0} {...props}>
      <ImageComponent src={whiteLine} zIndex={0} />
      <ImageComponent className="blur yellow" src={yellowLine} zIndex={1} />
      <ImageComponent className="blur white" src={whiteLine} zIndex={2} />
      <ImageComponent className="lights" src={circles1} zIndex={2} />
      <ImageComponent className="lights blink" src={circles2} zIndex={2} />
      <ImageComponent src={circles} zIndex={2} />
    </Light>
  );
}

export default Lights;
