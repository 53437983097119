import { Div, ImageComponent, Text } from 'differoute-styles';
import React from 'react';
import lug from 'assets/menu/lug.png';
import tree1 from 'assets/menu/tree1.png';
import tree2 from 'assets/menu/tree2.png';

import panel from 'assets/menu/logo-panel.png';

import firePlace from 'assets/menu/fireplace.png';

import snowman from 'assets/menu/snowman.png';
import oneSnowman from 'assets/menu/one-snowman.png';
import treeSnowman from 'assets/menu/snowman-tree.png';

import jelly from 'assets/menu/jelly.png';
import binch from 'assets/menu/binch.png';

import { useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import DifferouteLogo from 'components/DifferouteLogo/DifferouteLogo';
import loadingBox from 'assets/menu/loading.png';
import Ranking from './Ranking';

const Wrap = styled(Div)`
  p {
    font-size: 40px;
  }
`;

const shaking = keyframes`
0% {
  -webkit-transform: scale(1);
          transform: scale(1);
}
15% {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}
25% {
  -webkit-transform: scale(1);
          transform: scale(1);
}
35% {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}
50% {
  -webkit-transform: scale(1);
          transform: scale(1);
} 
`;

const FirePlaceMenu = styled(Div)`
  cursor: pointer;

  & > p {
    position: absolute;
    top: 100px;
    opacity: 0;
    transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition-delay: 0.2s;
  }
  & > img {
    transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  &:hover {
    & > img {
      transform: scale(1.05);
    }
    & > p {
      opacity: 1;
    }
  }
`;

const TreeMenu = styled(Div)`
  position: absolute;
  width: 456px;
  height: 414px;
  left: -32px;
  bottom: 35px;
  cursor: pointer;

  & > img {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }

  & > p {
    position: absolute;
    bottom: 140px;
    left: 100px;
    white-space: nowrap;
    opacity: 0;
    transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }

  &:hover {
    .shaking-tree {
      animation: ${shaking} 2s ease-in-out infinite both;
    }
    & > p {
      opacity: 1;
      left: 240px;
      z-index: 10;
    }
  }
`;

const CatMenu = styled(Div)`
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);

  & > img {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: ${(zIndex) => zIndex || 10};
  }

  & > p {
    position: absolute;
    white-space: nowrap;
    opacity: 0;
    z-index: 0;
    transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }

  & > .jelly {
    bottom: 100px;
    left: 20px;
  }

  & > .binch {
    bottom: 90px;
    left: 0;
  }

  &:hover {
    img {
      transform: scale(1.05);
    }

    & > .binch {
      opacity: 1;
      z-index: 11;
      left: 100px;
    }

    & > .jelly {
      opacity: 1;
      z-index: 11;
      bottom: 160px;
    }
  }
`;

const loader = keyframes`
  0%{color: #B60000;}
  50%{color:#19C720;}
    100%{color: #B60000;} `;

const loader2 = keyframes`
  0%{color: #19C720;}
  50%{color:#B60000;}
    100%{color: #19C720;} `;

const SnowManMenu = styled(Div)`
  cursor: pointer;
  & > img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
  }

  & > p {
    opacity: 0;
    position: absolute;
    top: 200px;
    right: 180px;
    transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    z-index: 0;
  }

  .loading {
    p {
      letter-spacing: 0.2em;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-shadow: unset !important;
    }

    span:nth-child(odd) {
      color: #19c720;
      animation: ${loader2} 1.5s ease infinite;
    }

    span:nth-child(2n) {
      color: #b60000;
      animation: ${loader} 1.5s ease infinite;
    }
  }

  &:hover {
    .shaking-snowman {
      animation: ${shaking} 2s ease-in-out infinite both;
    }
    & > p {
      opacity: 1;
      right: 380px;
      z-index: 1;
    }
  }
`;

function index(props) {
  const navigate = useNavigate();
  const ranking = [];
  const loading = false;
  return (
    <Wrap width="100%" height="100%" pt={100} position="relative">
      {ranking.length > 0 && <Ranking ranking={ranking} />}
      <ImageComponent
        src={lug}
        position="absolute"
        bottom="20px"
        left="50%"
        transform="translateX(-50%)"
      />
      <Div width={373} position="absolute" bottom={177} left={300}>
        <ImageComponent src={panel} width="100%" />
        <DifferouteLogo main zIndex={ranking && 0} />
      </Div>
      <TreeMenu onClick={() => navigate('jaejoon')}>
        <ImageComponent src={tree1} />
        <ImageComponent src={tree2} className="shaking-tree" zIndex={10} />
        <Text zIndex={10}>재주니를 지켜줭</Text>
      </TreeMenu>
      <FirePlaceMenu
        width={380}
        position="absolute"
        bottom={160}
        left="calc(50% + 200px)"
        transform="translateX(-50%)"
        onClick={() => navigate('/quiz/-1')}
      >
        <ImageComponent src={firePlace} width="100%" />
        <Text>디퍼루트 알아보기</Text>
      </FirePlaceMenu>
      <SnowManMenu
        width={486}
        height={514}
        position="absolute"
        bottom={40}
        right="-150px"
        onClick={() => navigate('/balance')}
      >
        <ImageComponent src={treeSnowman} width="100%" />
        <ImageComponent
          src={oneSnowman}
          width="100%"
          className="shaking-snowman"
        />
        <ImageComponent src={snowman} width="100%" />
        {loading && (
          <Div
            right="80%"
            left="unset !important"
            top="-10% !important"
            position="absolute"
            width={450}
            className="loading"
          >
            <ImageComponent src={loadingBox} width="100%" />
            <Text>
              <span>L</span>
              <span>O</span>
              <span>A</span>
              <span>D</span>
              <span>I</span>
              <span>N</span>
              <span>G</span>
              <span>.</span>
              <span>.</span>
              <span>.</span>
            </Text>
          </Div>
        )}

        <Text>재주니 꼬시기</Text>
      </SnowManMenu>
      <CatMenu
        position="absolute"
        width={148}
        bottom={60}
        left="calc(50% - 145px)"
        onClick={() => navigate('/soojyeong')}
        zIndex={(ranking.length > 0 && 0) || ''}
      >
        <ImageComponent src={jelly} />
        <Text className="jelly">슈졍</Text>
      </CatMenu>
      <CatMenu
        src={binch}
        position="absolute"
        width={138}
        bottom={50}
        left="calc(50% - 90px)"
        onClick={() => navigate('/donghak')}
        zIndex={(ranking.length > 0 && 0) || ''}
      >
        <ImageComponent src={binch} />
        <Text className="binch">동하기를 찾아라</Text>
      </CatMenu>
    </Wrap>
  );
}

export default index;
