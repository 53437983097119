import { Button, Col, Div, NewLineText, Text } from 'differoute-styles';
import React from 'react';

function Description({ title, content, close }) {
  return (
    <Col
      position="fixed"
      zIndex={9999}
      backgroundColor="rgba(255,255,255,0.7)"
      width="100vw"
      height="100vh"
      top="0"
      left="0"
      alignItems="center"
      justifyContent="center"
      gap={20}
    >
      <Text type="h1">{title}</Text>
      <NewLineText type="h3" content={content} />
      <Button label="START" active width={200} onClick={close} />
    </Col>
  );
}

export default Description;
