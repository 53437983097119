import { Div, ImageComponent, Row } from 'differoute-styles';
import React from 'react';

import openbox from 'assets/donghak/box_open.png';

import asset1 from 'assets/donghak/donghak.png';
import asset2 from 'assets/donghak/binch1.png';
import asset3 from 'assets/donghak/binch2.png';
import asset4 from 'assets/donghak/binch3.png';
import asset5 from 'assets/donghak/binch4.png';
import asset6 from 'assets/donghak/binch5.png';
import styled, { css, keyframes } from 'styled-components';

const vib = keyframes`
0% {
  -webkit-transform: translate(0);
          transform: translate(0);
}
10% {
  -webkit-transform: translate(-2px, -2px);
          transform: translate(-2px, -2px);
}
20% {
  -webkit-transform: translate(2px, -2px);
          transform: translate(2px, -2px);
}
30% {
  -webkit-transform: translate(-2px, 2px);
          transform: translate(-2px, 2px);
}
40% {
  -webkit-transform: translate(2px, 2px);
          transform: translate(2px, 2px);
}
50% {
  -webkit-transform: translate(-2px, -2px);
          transform: translate(-2px, -2px);
}
60% {
  -webkit-transform: translate(2px, -2px);
          transform: translate(2px, -2px);
}
70% {
  -webkit-transform: translate(-2px, 2px);
          transform: translate(-2px, 2px);
}
80% {
  -webkit-transform: translate(-2px, -2px);
          transform: translate(-2px, -2px);
}
90% {
  -webkit-transform: translate(2px, -2px);
          transform: translate(2px, -2px);
}
100% {
  -webkit-transform: translate(0);
          transform: translate(0);
}`;

const AnimImg = styled(ImageComponent)`
  animation: ${({ index }) =>
    (index === 2 &&
      css`
        ${vib} 0.5s linear infinite both
      `) ||
    'unset'};
`;

function DonghakPresenter({ gameArray, open }) {
  const assets = {
    binch1: asset2,
    binch2: asset3,
    donghak: asset1,
    binch3: asset4,
    binch4: asset5,
    binch5: asset6,
  };
  return (
    <Row
      position="relative"
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center"
    >
      {gameArray.map((game, index) => (
        <Div
          key={game.asset}
          position="absolute"
          width="15%"
          bottom={(index % 2 === 0 && '100px') || '300px'}
          left={`calc(${15 * index}% + 50px)`}
          className="total-box"
          transition="all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94)"
          onClick={(e) => open(e, index)}
        >
          <AnimImg
            width={(index === 2 && '110%') || '60%'}
            src={assets[game.asset]}
            position="absolute"
            left="30%"
            bottom="100%"
            className="random-box-img"
            zIndex={(index === 2 && 10) || 0}
            index={index}
          />
          <ImageComponent width="80%" src={openbox} className="random-box" />
        </Div>
      ))}
    </Row>
  );
}

export default DonghakPresenter;
