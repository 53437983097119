import { Col, Div, ImageComponent, Row, Span, Text } from 'differoute-styles';
import React from 'react';
import styled from 'styled-components';
import box from 'assets/menu/box.png';

const Title = styled(Text)`
  text-shadow: unset !important;
  position: relative;
  letter-spacing: 0.2em;

  & > span {
    letter-spacing: 0.2em;
    position: absolute;
    top: -4px;
    left: 3px;
    z-index: -1;
  }
`;

const Wrap = styled(Col)`
  & p {
    text-shadow: unset !important;
  }
`;

function Ranking({ ranking }) {
  return (
    <Wrap
      borderRadius="30px"
      border={{ width: '5px', color: 'primary' }}
      backgroundColor="#000"
      width="80vw"
      height="80vh"
      position="absolute"
      zIndex={1}
      left="50%"
      transform="translateX(-50%)"
      alignItems="center"
      justifyContent="space-between"
      padding="50px 0"
      style={{ textShadow: 'unset !important' }}
      bottom="20px"
    >
      <Title color="#3FED1C">
        <Span color="#C82828">CONGRATULATIONS!</Span>
        CONGRATULATIONS!
      </Title>
      <Div width="100%">
        {ranking.map((ranker, index) => (
          <Row
            width="100%"
            mt={10}
            alignItems="center"
            justifyContent="space-between"
            padding="0 20%"
            key={ranker.name}
          >
            <Row>
              <Text color="white">{index + 1}.</Text>
              <Text color={(index % 2 === 0 && '#3FED1C') || '#C82828'} ml={30}>
                {ranker.name}
              </Text>
            </Row>
            <Row gap={30} alignItems="center">
              <ImageComponent src={box} height={30} />
              <Text color="white">*</Text>
              <Text minWidth={71} color="white" textAlign="right">
                {ranker.score}
              </Text>
            </Row>
          </Row>
        ))}
      </Div>
    </Wrap>
  );
}

export default Ranking;
