import { Button, Col, Div, Text } from 'differoute-styles';
import React, { useCallback, useEffect, useState } from 'react';
import { useInterval } from 'react-use';
import Description from 'components/Description';
import JaejoonPresenter from './JaejoonPresenter';
import './Jaejoon.css';

function JaejoonContainer(props) {
  const delay = 100;
  const [isRunning, setIsRunning] = useState(false);
  const [seconds, setSeconds] = useState(parseInt(0, 10));

  const [persentage, setPersentage] = useState(0);

  const [alert, setAlert] = useState();
  const play = () => {
    setIsRunning(!isRunning);
    if (isRunning) {
      const balloon = document.getElementById('balloon');
      const currentWidth = balloon.clientWidth;
      const allHeight = Math.round(window.innerHeight * 0.7) - 40;
      setAlert(
        `${Math.round(
          (currentWidth / allHeight) * 100,
        )}% 불었어요!\n재주니를 무사히 지켰어요!😝`,
      );
    }
  };

  useInterval(
    () => {
      if (isRunning) {
        const balloon = document.getElementById('balloon');
        const currentWidth = balloon.clientWidth;
        const allHeight = Math.round(window.innerHeight * 0.7) - 40;
        setSeconds(seconds + 1);
        balloon.style.width = `${10 + (seconds + 1)}vh`;
        setPersentage(Math.round((currentWidth / allHeight) * 100));

        if (allHeight === currentWidth) {
          setPersentage(0);
          setIsRunning(false);

          balloon.style.width = '0vh';
          setAlert(`풍선이 터졌어요!\n웅근사슴이 재주니 풍선을 부셔버렸어요😢`);
        }
      }
    },
    isRunning ? delay : null,
  );

  const handlePlay = useCallback(
    (e) => {
      e.preventDefault();
      if (!alert && e.which === 32) {
        play();
      }
    },
    [isRunning],
  );

  useEffect(() => {
    window.addEventListener('keydown', handlePlay);

    return () => {
      window.removeEventListener('keydown', handlePlay);
    };
  }, [handlePlay]);

  const restart = () => {
    const balloon = document.getElementById('balloon');
    setIsRunning(false);
    setSeconds(parseInt(0, 10));
    setAlert();
    setPersentage(0);
    balloon.style.width = '10vh';
  };

  const [enter, setEnter] = useState(true);

  return (
    <>
      {enter && (
        <Description
          close={() => setEnter(false)}
          title="재주니를 지켜라"
          content={`송년회 개회사를 하기 싫었던 재주니!\n풍선을 타고 도망가려고 하지만\n가만 두고 볼 수 없었던 웅그니가 풍선을 터트리려한다!\n웅그니가 풍선을 터트리기 전에 풍선을 최대한 크게 불어\n송년회를 탈출하자!`}
        />
      )}
      {alert && (
        <Col
          position="fixed"
          width="100vw"
          height="100vh"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          color="white"
          backgroundColor="rgba(255,255,255,0.4)"
          fontSize={60}
          textAlign="center"
          whiteSpace="nowrap"
          zIndex={999}
          alignItems="center"
          justifyContent="center"
        >
          <h1 className="jaejoon">
            <span>{alert.split('\n')[0]}</span>
            <br />
            <span>{alert.split('\n')[1]}</span>
            <br />
          </h1>
          <Button label="다시 시작" active onClick={restart} mt={20} />
        </Col>
      )}
      <JaejoonPresenter
        play={play}
        isRunning={isRunning}
        persentage={persentage}
      />
    </>
  );
}

export default JaejoonContainer;
