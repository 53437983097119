import { Div } from 'differoute-styles';
import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import backgroundImage from 'assets/background.jpg';
import FallSnow from 'components/FallSnow/FallSnow';
import Lights from 'components/Lights/Lights';
import DifferouteLogo from 'components/DifferouteLogo/DifferouteLogo';

function MainPresenter({ play }) {
  const location = useLocation().pathname.split('/');
  return (
    <Div
      backgroundImage={backgroundImage}
      width="100vw"
      height="100vh"
      backgroundSize="cover"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
    >
      <Div
        position="fixed"
        zIndex={9999}
        backgroundColor="#C82828"
        borderRadius="50%"
        width={12}
        height={12}
        right="5px"
        top="5px"
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
          play();
        }}
      />
      {/* <FallSnow /> */}
      <Lights />
      {location[1] && <DifferouteLogo main={false} />}
      <Div
        width="100vw"
        height="calc(100vh - 60px)"
        position="absolute"
        zIndex={10}
        top="60px"
      >
        <Outlet />
      </Div>
    </Div>
  );
}

export default MainPresenter;
