import { Col, Div, ImageComponent, Row, Span } from 'differoute-styles';
import React from 'react';
import './quiz.scss';
import snow1 from 'assets/quiz/snow1.png';
import snow2 from 'assets/quiz/snow2.png';
import snow3 from 'assets/quiz/snow3.png';
import o from 'assets/quiz/o.png';
import x from 'assets/quiz/x.png';

function QuizPresenter({
  quiz,
  selectAnswer,
  selectedAnswer,
  result,
  nextCheck,
}) {
  const snows = [snow1, snow2, snow3];
  return (
    <Col
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="100%"
      gap={30}
    >
      {result && (
        <ImageComponent
          src={(result === '정답' && o) || x}
          position="fixed"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          zIndex={99999}
          onClick={nextCheck}
        />
      )}
      <div className="quiz-title">
        {quiz?.title}
        {quiz?.score && (
          <Span display="block" fontSize={35} mt={10}>
            (상자{quiz?.score}개)
          </Span>
        )}
      </div>
      <Col width="100%" padding="0 15%">
        {quiz?.answers?.map((answer, index) => (
          <Div key={answer} position="relative">
            <Row
              className="button"
              cursor="pointer"
              onClick={() => selectAnswer(index)}
              style={
                (selectedAnswer.includes(index) && {
                  transform: 'scale(1.05)',
                }) ||
                {}
              }
            >
              <span>{answer}</span>
            </Row>
            <ImageComponent
              src={(index === 3 && snows[1]) || snows[index]}
              position="absolute"
              zIndex={50}
              top={(index === 0 && '-15px') || '-5px'}
              right={(index === 0 && '15px') || (index % 2 === 1 && '50px')}
              left={index === 2 && '30px'}
            />
          </Div>
        ))}
      </Col>
    </Col>
  );
}

export default QuizPresenter;
