import { Div, ImageComponent, Row, Text } from 'differoute-styles';
import React from 'react';
import lug from 'assets/menu/lug.png';
import tree1 from 'assets/balance/atree.png';
import tree2 from 'assets/balance/btree.png';
import panel from 'assets/menu/logo-panel.png';
import firePlace from 'assets/menu/fireplace.png';
import styled from 'styled-components';

import woong from 'assets/balance/woong.png';
import jae from 'assets/balance/jae.png';
import su from 'assets/balance/su.png';
import min from 'assets/balance/min.png';
import dong from 'assets/balance/dong.png';
import ji from 'assets/balance/ji.png';
import useDrag from 'hook/useDrag';

const Wrap = styled(Div)`
  p {
    font-size: 40px;
  }
`;

const FirePlaceMenu = styled(Div)`
  & > p {
    position: absolute;
    top: 100px;
    opacity: 0;
    transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition-delay: 0.2s;
  }
  & > img {
    transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
`;

function BalancePresenter({ enter }) {
  const { posX, posY, onDragStart, onDrag, onDragEnd } = useDrag();
  const users = [
    { img: woong },
    { img: jae },
    { img: dong },
    { img: min },
    { img: ji },
    { img: su },
  ];
  return (
    <Wrap width="100%" height="100%" pt={100} position="relative">
      <ImageComponent
        src={lug}
        position="absolute"
        bottom="20px"
        left="50%"
        transform="translateX(-50%)"
      />
      <Div width={450} position="absolute" bottom={177} left={400}>
        <ImageComponent src={panel} width="100%" />
        <Row
          position="absolute"
          top="50px"
          left="30px"
          alignItems="center"
          gap={5}
          style={{ transform: 'rotate(-10deg)' }}
        >
          <Text
            whiteSpace="nowrap"
            textAlign="center"
            style={{ fontSize: '30px' }}
          >
            A<br />
            인어로 살기
          </Text>
          <Text style={{ fontSize: '25px' }}>vs</Text>
          <Text
            whiteSpace="nowrap"
            textAlign="center"
            style={{ fontSize: '30px' }}
          >
            B<br />
            어인으로 살기
          </Text>
        </Row>
      </Div>
      <FirePlaceMenu
        width={380}
        position="absolute"
        bottom={160}
        left="calc(50% + 200px)"
        transform="translateX(-50%)"
      >
        <ImageComponent src={firePlace} width="100%" />
      </FirePlaceMenu>
      <ImageComponent
        src={tree1}
        position="fixed"
        left="0"
        top="0"
        height="100%"
      />
      <ImageComponent
        src={tree2}
        position="fixed"
        right="0"
        top="0"
        height="100%"
      />
      {!enter &&
        users.map((user, index) => (
          <Div
            backgroundImage={user.img}
            key={index}
            width={90}
            height={90}
            backgroundSize="100%"
            className="dragItem"
            draggable="true"
            position="fixed"
            zIndex={9999}
            left={(posX !== 10 && posX) || `calc(40% + ${100 * (index % 3)}px)`}
            top={(posY !== 10 && posY) || ''}
            bottom={(posY === 10 && ((index > 2 && '140px') || '40px')) || ''}
            onDragStart={onDragStart}
            onDrag={onDrag}
            onDragEnd={onDragEnd}
          />
        ))}
    </Wrap>
  );
}

export default BalancePresenter;
