export default function useDrag() {
  let posX = 10;
  let posY = 10;

  const onDragStart = (e) => {
    const img = new Image();
    e.dataTransfer.setDragImage(img, 0, 0);
    posX = e.clientX;
    posY = e.clientY;
  };

  const onDrag = (e) => {
    if (e.clientX !== 0 || e.clientY !== 0) {
      console.log(posX);
      e.target.style.left = `${e.target.offsetLeft + e.clientX - posX}px`;
      e.target.style.top = `${e.target.offsetTop + e.clientY - posY}px`;
      posX = e.clientX;
      posY = e.clientY;
    }
  };

  const onDragEnd = (e) => {
    e.target.style.left = `${e.target.offsetLeft + e.clientX - posX}px`;
    e.target.style.top = `${e.target.offsetTop + e.clientY - posY}px`;
  };

  return { onDragStart, onDrag, onDragEnd, posX, posY };
}
