/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Div } from 'differoute-styles';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './DifferouteLogo.css';

function DifferouteLogo({ main, ...props }) {
  const navigate = useNavigate();
  return (
    <Div
      position="absolute"
      top={(main && '75px') || '10px'}
      left={(main && 'calc(50% + 10px)') || '-40px'}
      transform={(main && 'translateX(-50%)') || ''}
      cursor="pointer"
      onClick={() => navigate('/')}
      zIndex={999}
      {...props}
    >
      <div
        className="background"
        style={{ transform: (main && 'rotate(-7deg)') || 'rotate(0deg)' }}
      >
        <div className="heart">
          <div
            className="word"
            style={{ transform: (!main && 'scale(0.5)') || 'scale(1)' }}
          >
            DIFFEROUTE
          </div>
        </div>
      </div>
    </Div>
  );
}

export default DifferouteLogo;
