import Description from 'components/Description';
import React, { useState } from 'react';
import BalancePresenter from './BalancePresenter';

function BalanceContainer(props) {
  const [enter, setEnter] = useState(true);
  return (
    <>
      {enter && (
        <Description
          close={() => setEnter(false)}
          title="재주니 꼬시기"
          content={`이래도 에에~ 저래도 에에~ \n매력적인 의견으로 재주니를 꼬셔보자!\n(침소리 가능 / 협박 불가능 / 뇌물 불가능)`}
        />
      )}
      <BalancePresenter enter={enter} />
    </>
  );
}

export default BalanceContainer;
