import React, { useEffect, useState } from 'react';
import bgm from 'assets/christmas.mp3';
import MainPresenter from './MainPresenter';

function MainContainer(props) {
  const audio = new Audio(bgm);
  let isStart = false;
  const play = () => {
    if (!isStart) {
      isStart = true;
      audio.volume = 0.05;
      audio.loop = true;
      audio.play();
    }
  };

  useEffect(
    () => () => {
      audio.currentTime = 0;
      audio.pause();
    },
    [],
  );

  return <MainPresenter play={play} />;
}

export default MainContainer;
