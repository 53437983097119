/* eslint-disable import/prefer-default-export */
export const quizs = [
  {
    title: '동학님의 최애 베스킨라빈스 맛은?',
    answer: 2,
    score: 3,
    answers: [
      '엄마는 외계인',
      '오레오 쿠키 앤 크림',
      '허니치즈트랩',
      '아몬드 봉봉',
    ],
  },
  {
    title: '지영님의 개명 전 이름은?',
    answer: 0,
    score: 5,
    answers: ['이보람', '이지혜', '이선민', '이진이'],
  },
  {
    title: '다음 중 민주님이 연주할 수 있는 악기는? (복수 선택)',
    answer: [0, 3],
    score: 4,
    answers: ['플룻', '바이올린', '오카리나', '피아노'],
  },
  {
    title: '수정님이 해 본 모든 아르바이트 목록이 있는 것은?',
    answer: 0,
    score: 5,
    answers: [
      '편의점, 캐리비안베이, 치킨집, 피씨방',
      '에버랜드, 편의점, 치킨집, 올리브영',
      '피씨방, 올리브영, 치킨집, 편의점',
      '케리비안베이, 에버랜드, 치킨집, 편의점',
    ],
  },
  {
    title: (
      <>
        [BONUS] 이번 크리스마스 웅근님은
        <br />
        '검은사막'(게임)에 얼마를 현질했을까?
      </>
    ),
    answer: 1,
    score: 10,
    answers: ['10만원', '20만원', '30만원', '40만원'],
  },
  {
    title: (
      <>
        디퍼루트 내에서
        <br />
        다른 포지션으로 근무 할 수 있다고 한다면
        <br />
        재준님은 어떤 팀에서 업무를 하고 싶어할까?'
      </>
    ),
    answer: 3,
    score: 3,
    answers: ['브랜드 디자인팀', '개발팀', '인사팀', '사업개발팀'],
  },
];
